import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Api from "../../api";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AdmUnitModal from "../../components/Adm/AdmUnitModal";
import AdmUnitTable from "../../components/Adm/AdmUnitTable";
import LoadingPage from "../LoadingPage";
import TagChip from "../../components/Qr/TagChip";


function AdmUnitListPage(props) {

  document.title = 'Все позиции';

  // const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [catLoading, setCatLoading] = useState(true);
  const [categories, setCategories] = useState({});

  const {place, id} = useParams();
  const [filter, setFilter] = useState({search:'',tag:0,active:false,category:id,perpage:25,page:1});

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const reloadPage = () =>{
    // setLoading(true);
    // let data = {place:place, ...filter};
    // setFilter({...filter, category:id})
    setFilter({...filter});
  };

  useEffect(()=>{
    // reloadPage();
  }, [place,id,filter])

  useEffect(()=>{
    setCatLoading(true);
    Api.post('/public/categories', {place:place, nomenu:true, all:true})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCategories([{name:"Верхний уровень", id:0}, ...data.categories]))
      .catch()
      .finally(()=>{setCatLoading(false)})
  },[place])
  const goBack = () => navigate(-1);


  if(loading)
    return (<LoadingPage />);
  return (
    <>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'} justifyContent={'space-between'}>
        <Typography variant={'h5'}>
          {/*{category?.name ? `Позиции из категории меню '${category.name}'` : "Все позиции"}*/}
          Позиции
        </Typography>
        <Box>
          {/*<FormControl sx={{ m: 1, minWidth: 180 }} size="small">*/}
          {/*  <InputLabel id="demo-select-small">По тегу</InputLabel>*/}
          {/*  <Select sx={{minWidth: 180 }}*/}
          {/*          labelId="demo-select-small"*/}
          {/*          id="demo-select-small"*/}
          {/*          value={searchTag}*/}
          {/*          label="По тегу"*/}
          {/*          onChange={(e) => {setSearchTag (e.target.value);}}*/}
          {/*  >*/}
          {/*    <MenuItem value={0}>Без разницы</MenuItem>*/}
          {/*    {searchTagList.map((i, index) => (*/}
          {/*      <MenuItem key={index} value={i.id}>{i.name}:&nbsp;<TagChip tag={i} /></MenuItem>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}
          <FormControlLabel control={<Switch
            checked={filter.active}
            onChange={(e)=>{setFilter({...filter, active:e.target.checked});}}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Только активные" />

          <TextField variant={'outlined'} label='Поиск' type={'search'} value={filter.search}
                     size="small"
                     onChange={(e)=>{setFilter({...filter, search:e.target.value});}}
          />
          <Button variant={'contained'} onClick={reloadPage}>Поиск</Button>
        </Box>

      </Box>
       <Box py={'0.5rem'} display={'flex'} gap={'10px'}>
        <Button onClick={()=>navigate(-1)} variant={"outlined"}>Назад</Button>
         {!catLoading && <AdmUnitModal color={'success'} categories={categories}
              place={place} text={<AddBoxIcon />} variant={'contained'}
               newitem reload={reloadPage}
        />}
      </Box>


      {!catLoading
        && <AdmUnitTable filter={filter} categories={categories ?? {}}
                         updateFilter={setFilter}/>
      }
    </>
  );
}

export default AdmUnitListPage;